<template>
    <div>
        <div class='home-btn d-none d-sm-block'>
            <a href='/'>
                <i class='mdi mdi-home-variant h2 text-white'></i>
            </a>
        </div>
        <div>
            <div class='container-fluid p-0'>
                <div class='row no-gutters'>
                    <div class='col-lg-4'>
                    </div>
                    <div class='col-lg-4'>
                        <div class='authentication-page-content p-4 d-flex align-items-center min-vh-100'>
                            <div class='w-100'>
                                <div class='row justify-content-center'>
                                    <div class='col-lg-9'>
                                        <div>
                                            <div class='text-center'>
                                                <div>
                                                <a href='/' class='logo'>
                                                    <img src='../../assets/images/logo-dark.png' height='90' alt='logo' />
                                                </a>
                                                </div>

                                                <h4 class='font-size-18 mt-4'>Bienvenido !</h4>
                                                <p class='text-muted'>Inicie sesión en su cuenta de administrador MaxParking.</p>
                                            </div>

                                            <b-alert
                                                variant='danger'
                                                class='mt-3'
                                                v-if='authError'
                                                show
                                                dismissible
                                            >{{authError}}</b-alert>

                                            <div class='p-2 mt-5'>
                                                <form class='form-horizontal' @submit.prevent='doLogin'>
                                                    <div class='form-group auth-form-group-custom mb-4'>
                                                        <i class='ri-mail-line auti-custom-input-icon'></i>
                                                        <label for='username'>Código de operador</label>
                                                        <input
                                                        type='username'
                                                        v-model='username'
                                                        class='form-control'
                                                        id='username'
                                                        placeholder='Ingrese su código de operador'
                                                        :class="{ 'is-invalid': submitted && $v.username.$error }"
                                                        />
                                                        <div v-if='submitted && $v.username.$error' class='invalid-feedback'>
                                                        <span v-if='!$v.username.required'>El operador es requerido.</span>
                                                        </div>
                                                    </div>

                                                    <div class='form-group auth-form-group-custom mb-4'>
                                                        <i class='ri-lock-2-line auti-custom-input-icon'></i>
                                                        <label for='password'>Contraseña</label>
                                                        <input
                                                        v-model='password'
                                                        type='password'
                                                        class='form-control'
                                                        id='password'
                                                        placeholder='Enter password'
                                                        :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                        />
                                                        <div
                                                        v-if='submitted && !$v.password.required'
                                                        class='invalid-feedback'
                                                        >La contraseña es requerida.</div>
                                                    </div>

                                                    <div class='mt-4 text-center'>
                                                        <input type="submit" value="Iniciar Sesión" class='btn btn-primary w-md waves-effect waves-light'>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class='mt-5 text-center'>
                                                <p>
                                                © 2024 MaxParking
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-lg-4'>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import { login } from '../../services/operators.service'

export default {
    data() {
        return {
        username: '',
        password: '',
        authError: null,
        submitted: false
        };
    },
    validations: {
        username: { required },
        password: { required }
    },
    methods: {
        async doLogin() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                const auth = await login({
                    operator_id: this.username,
                    password: this.password,
                }).catch((error) => {
                    console.log(error)
                })

                if (auth.status >= 400) {
                    if (auth.status === 400) {
                        const errors = Object.values(auth.errors)
                        errors.forEach(error => this.authError += (this.authError === null) ? '': `${error[0]} <br/>` )
                    } else {
                        this.authError = auth.errors.status
                        
                    }
                } else {
                    const session_id = auth.data.session_id
                    localStorage.setItem('session_id', session_id)

                    await this.$router.push(
                        {name: 'pos'}
                    )
                }
            }
        }
    }
}
</script>
